import cn from 'classnames';
import { ReactElement } from 'react';

interface GridProps {
    children: Array<ReactElement> | ReactElement;
    cols?: 2 | 3 | 4;
    className?: string;
}

export const Grid = ({ children, cols = 2, className }: GridProps) => {
    return (
        <div
            className={cn(
                className,
                'grid grid-cols-1 gap-x-16 gap-y-8 md:gap-y-12',
                cols === 4 ? 'md:grid-cols-4' : cols === 3 ? 'md:grid-cols-3' : 'md:grid-cols-2'
            )}
        >
            {children}
        </div>
    );
};

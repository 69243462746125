import React, { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react';
import { Client, Draft, Policy } from '../types';
import { FiltersProps, SortProps } from '../../../../components/DataTable/DataTable';
import { RecordsOnPageList } from '../../../../components/DataTable/components/RecordsOnPage';

export interface DashboardBaseList<T> {
    loading: boolean;
    isInvalid: boolean;
    isError: boolean;
    page: number;
    recordsOnPage: number;
    fullText: string;
    sort: SortProps;
    filters: FiltersProps;
    records: T[];
}

interface DashboardContextDrafts extends DashboardBaseList<Draft> {}
interface DashboardContextMyClients extends DashboardBaseList<Client> {}
interface DashboardContextPolicy extends DashboardBaseList<Policy> {}

interface DashboardContextType {
    drafts: DashboardContextDrafts;
    setDrafts: Dispatch<SetStateAction<DashboardContextDrafts>>;
    myClients: DashboardContextMyClients;
    setMyClients: Dispatch<SetStateAction<DashboardContextMyClients>>;
    agencyClients: DashboardContextMyClients;
    setAgencyClients: Dispatch<SetStateAction<DashboardContextMyClients>>;
    myPolicy: DashboardContextPolicy;
    setMyPolicy: Dispatch<SetStateAction<DashboardContextPolicy>>;
    agencyPolicy: DashboardContextPolicy;
    setAgencyPolicy: Dispatch<SetStateAction<DashboardContextPolicy>>;
}

interface ContextProps {
    children: ReactNode;
}

const initialContextObject = {
    loading: false,
    isInvalid: false,
    isError: false,
    page: 0,
    recordsOnPage: RecordsOnPageList[0],
    fullText: '',
    filters: {},
    records: [],
};

const initialDrafts: DashboardContextDrafts = {
    ...initialContextObject,
    sort: {
        sortField: 'createdAt',
        sortBy: 'desc',
    },
};

const initialClients: DashboardContextMyClients = {
    ...initialContextObject,
    sort: {
        sortField: 'createdAt',
        sortBy: 'desc',
    },
};

const initialPolicy: DashboardContextPolicy = {
    ...initialContextObject,
    sort: {
        sortField: 'createdAt',
        sortBy: 'desc',
    },
};

export const DashboardContext = createContext<DashboardContextType>({
    drafts: initialDrafts,
    setDrafts: () => {},
    myClients: initialClients,
    setMyClients: () => {},
    agencyClients: initialClients,
    setAgencyClients: () => {},
    myPolicy: initialPolicy,
    setMyPolicy: () => {},
    agencyPolicy: initialPolicy,
    setAgencyPolicy: () => {},
});

const Context = ({ children }: ContextProps) => {
    const [drafts, setDrafts] = useState<DashboardContextDrafts>(initialDrafts);
    const [myClients, setMyClients] = useState<DashboardContextMyClients>(initialClients);
    const [agencyClients, setAgencyClients] = useState<DashboardContextMyClients>(initialClients);
    const [myPolicy, setMyPolicy] = useState<DashboardContextPolicy>(initialPolicy);
    const [agencyPolicy, setAgencyPolicy] = useState<DashboardContextPolicy>(initialPolicy);

    return (
        <DashboardContext.Provider
            value={{
                drafts,
                setDrafts,
                myClients,
                setMyClients,
                agencyClients,
                setAgencyClients,
                myPolicy,
                setMyPolicy,
                agencyPolicy,
                setAgencyPolicy,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

export default Context;

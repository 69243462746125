import { useContext, useEffect, useRef, useState } from 'react';
import { Form, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { CustomFormik } from '../../components/CustomFormik';
import { Head } from '../../components/Head';
import { AppContext } from '../../components/Context';
import { ArrangementKind } from '../../types/enums/ArrangementKind';
import { IdentityConfirmationSourceType } from '../../types/enums/IdentityConfirmationSourceType';
import { getHigherStep, getOwner } from '../../utils';
import Loader from '../../components/common/Loader';
import { useAppNavigate, useContextHelpers } from '../../utils/hooks';
import { callGenerateOnlinePolicyAttachmentCode, callGenerateMojeIdCode } from '../../apis/documents';
import { ROUTE, STEP_CODE } from '../../constants';
import IdentityVerificationForm from './IdentityVerificationForm';
import LimitedAccess from '../../components/LimitedAccess';
import { Layout } from '../../components/Layout';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';
import { IdentityConfirmatorType } from '../../types/enums/IdentityConfirmatorType';
import useIdentityVerificationHelpers from './helpers';
import { MojeIdGetClientDataResponse } from '../../types/model';
import { callMojeIdGetPersistedData, parseMojeIdPersistedClientDataResponse } from '../../apis/moje-id';

export const IdentityVerification = () => {
    const { t } = useTranslation();
    const { mojeIdExistence, setMojeIdExistence, refetchMojeIdExistence, ...ctx } = useContext(AppContext);
    const { withoutDocumentPhotos, clientPhoneNumber, isIdentificationMojeId, existsMojeIdExistence } =
        useContextHelpers();
    const { navigateTo } = useAppNavigate();
    const [isValid, setIsValid] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const data = ctx.currentModel;
    const { ExternalId } = data || {};
    const ParticipantExternalId = data?.Participants[0].ExternalId;
    const policyOwner = data ? getOwner(data) : null;
    const token = localStorage.getItem('token') || '';
    const myFormikRef = useRef<FormikProps<any>>(null);
    const [mojeIdConfirmedClientData, setMojeIdConfirmedClientData] = useState<MojeIdGetClientDataResponse | null>(
        null
    );
    const disabledFromMojeId = !!(isIdentificationMojeId && existsMojeIdExistence);

    const [loading, setLoading] = useState(disabledFromMojeId && !mojeIdConfirmedClientData);

    const { isDeclarationRequired, isPersonalDataRequired, isPersonalDataSimpleRequired, isConfirmatorTypeRequired } =
        useIdentityVerificationHelpers();

    const hasGenerateMojeIdCodeSended = () => data?.Settings?.GenerateMojeIdCode === clientPhoneNumber;

    const lastOnlinePolicyAttachmentCodePhoneNumber = data?.Settings?.OnlinePolicyAttachmentCodePhoneNumber;

    const formSchema: any = Yup.object().shape({
        declaration: isDeclarationRequired
            ? Yup.boolean()
                  .required()
                  .oneOf([true], t('common.formErrors.requiredField') || '')
            : Yup.boolean(),
        personalData: Yup.string()
            .nullable()
            .test(t('common.formErrors.requiredField'), function (value) {
                return (
                    !(isPersonalDataRequired(this.parent) || isPersonalDataSimpleRequired(this.parent)) ||
                    ((isPersonalDataRequired(this.parent) || isPersonalDataSimpleRequired(this.parent)) && !!value)
                );
            }),
        confirmatorType: Yup.string()
            .nullable()
            .test(t('common.formErrors.requiredField'), function (value) {
                return !isConfirmatorTypeRequired(this.parent) || (isConfirmatorTypeRequired(this.parent) && !!value);
            }),
    });

    useEffect(() => {
        refetchMojeIdExistence();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isIdentificationMojeId && existsMojeIdExistence) {
            if (ExternalId && ParticipantExternalId && token) {
                callMojeIdGetPersistedData({
                    data: {
                        onlinePolicyExternalId: ExternalId,
                        participantExternalId: ParticipantExternalId,
                    },
                    token,
                })
                    .then((response) => response.data)
                    .then((result) => setMojeIdConfirmedClientData(parseMojeIdPersistedClientDataResponse(result)));
            }
        }
    }, [ExternalId, ParticipantExternalId, token, isIdentificationMojeId, existsMojeIdExistence]);

    useEffect(() => {
        if (loading && mojeIdConfirmedClientData) {
            setLoading(false);
        }
    }, [loading, mojeIdConfirmedClientData]);

    if (loading) {
        return (
            <Layout continueDisabled>
                <LimitedAccess minStep={STEP_CODE.CONTACTS_CHECK}>
                    <Head heading1={t('pages.identityVerification.title')} />
                    <Loader />
                </LimitedAccess>
            </Layout>
        );
    }

    return (
        <Layout continueDisabled={continueDisabled || !isValid}>
            {data ? (
                <LimitedAccess minStep={STEP_CODE.CONTACTS_CHECK}>
                    <Head heading1={t('pages.identityVerification.title')} />

                    <CustomFormik
                        initialValues={{
                            declaration: !!policyOwner?.IdentityConfirmation?.IdentityConfirmationSourceType ?? false,
                            personalData:
                                policyOwner?.IdentityConfirmation?.IdentityConfirmationSourceType?.toString() ?? null,
                            confirmatorType:
                                policyOwner?.IdentityConfirmation?.IdentityConfirmatorType?.toString() ?? null,
                        }}
                        onSubmit={async (v) => {
                            setContinueDisabled(true);
                            if (token) {
                                const lastConfirmator =
                                    data.Settings?.IllustrationSettings?.IdentityConfirmatorType ?? '';

                                const newData = {
                                    ...data,
                                    Settings: {
                                        ...data.Settings,
                                        IllustrationSettings: {
                                            ...data?.Settings.IllustrationSettings,
                                            IdentityConfirmatorType: v?.confirmatorType,
                                        },
                                        CurrentStepCode: getHigherStep(
                                            data.Settings.CurrentStepCode,
                                            STEP_CODE.IDENTITY_VERIFICATION
                                        ),
                                    },
                                };

                                if (withoutDocumentPhotos) {
                                    newData.PolicyOwners[0].IdentityConfirmation.IdentityConfirmatorType =
                                        IdentityConfirmatorType.Agent;
                                }

                                let nextStep = null;

                                if (v?.personalData === IdentityConfirmationSourceType.MojeId.toString()) {
                                    if (!hasGenerateMojeIdCodeSended()) {
                                        await callGenerateMojeIdCode({
                                            data: { onlinePolicyExternalId: data.ExternalId },
                                            token,
                                        });
                                        newData.Settings.GenerateMojeIdCode = clientPhoneNumber;
                                    }
                                    nextStep = ROUTE.MOJE_ID;
                                } else if (withoutDocumentPhotos) {
                                    nextStep = ROUTE.ID_DETAILS;
                                } else if (v?.personalData === IdentityConfirmationSourceType.Photocopy.toString()) {
                                    if (
                                        v.confirmatorType !== lastConfirmator?.toString() ||
                                        !lastConfirmator ||
                                        lastOnlinePolicyAttachmentCodePhoneNumber !== clientPhoneNumber
                                    ) {
                                        // odeslání SMS s pokyny k naskenování dokumentů, 1 agentovi, 2 klientovi
                                        await callGenerateOnlinePolicyAttachmentCode({
                                            data: {
                                                onlinePolicyExternalId: data.ExternalId,
                                                generateOnlinePolicyAttachmentCodePlType: parseInt(v.confirmatorType),
                                            },
                                            token,
                                        });
                                        newData.Settings.OnlinePolicyAttachmentCodePhoneNumber = clientPhoneNumber;
                                    }
                                    nextStep = ROUTE.DOCUMENT_PHOTO;
                                }

                                ctx.setCurrentModel(newData);
                                await ctx.saveCurrentModel(newData);

                                setContinueDisabled(false);
                                if (nextStep) {
                                    navigateTo(nextStep);
                                }
                            }
                        }}
                        className="flex flex-col gap-y-10"
                        customRender
                        validationSchema={formSchema}
                        passedRef={myFormikRef}
                    >
                        {({ values, onChange }: FormikValues) => {
                            return (
                                <Form noValidate onChange={onChange}>
                                    <DetectFormikChanged
                                        onChange={(v: FormikValues) => {
                                            if (formSchema.isValidSync(v) !== isValid) {
                                                setIsValid(formSchema.isValidSync(v));
                                            }

                                            ctx.setCurrentModel({
                                                ...data,
                                                PolicyOwners: [
                                                    {
                                                        ...data.PolicyOwners[0],
                                                        IdentityConfirmation: {
                                                            ...data.PolicyOwners[0].IdentityConfirmation,
                                                            IdentityConfirmationSourceType:
                                                                parseInt(v?.personalData) || null,
                                                            IdentityConfirmatorType:
                                                                parseInt(v?.confirmatorType) || null,
                                                        },
                                                    },
                                                ],
                                                Settings: {
                                                    ...data.Settings,
                                                    IsIdentityConfirmed:
                                                        data.Settings.ArrangementType === ArrangementKind.F2F,
                                                },
                                            });
                                        }}
                                    />

                                    <IdentityVerificationForm values={values} disabled={disabledFromMojeId} />
                                </Form>
                            );
                        }}
                    </CustomFormik>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};

import { Dispatch, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';

import i18n from '../i18n';
import { ErrorType, PopupType, UserDataType } from '../types';
import { getToken, isFinishedLocation, isTokenExpired } from '../utils';
import { HUMAN_DATE_FORMAT, ROUTE } from '../constants';
import { ModelProps } from '../types/model';
import { pushStateListener } from '../utils';
import { callSaveModelData } from '../apis/modelData';
import { callGetDashboardRights } from '../apis/authentications';
import { callCheckMojeidDataExistence, CallCheckMojeidDataExistenceResponse } from '../apis/support';

export interface AppContextProps {
    popupType: PopupType | null;
    showPopup: (popupType: PopupType | null, popupCallback?: () => void) => void;
    popupCallback: any;
    currentFormik: any;
    setCurrentFormik: (formik: any) => void;
    predefinedIsPep: boolean | undefined;
    setPredefinedIsPep: (value: boolean | undefined) => void;
    initData: any;
    setInitData: (data: any | null) => void;
    initDataFetchDate: Dayjs | null;
    setInitDataFetchDate: (data: Dayjs | null) => void;
    userData: UserDataType | null;
    setUserData: (data: UserDataType | null) => void;
    basicSuggestedInsurace: any;
    setBasicSuggestedInsurace: (value: any) => void;
    currentModel: ModelProps | null;
    setCurrentModel: Dispatch<SetStateAction<ModelProps | null>>;
    clearCurrentModel: (force?: boolean) => void;
    saveCurrentModel: (data: ModelProps) => Promise<AxiosResponse<any, any>>;
    coverChangedCategories: string[];
    coverChangedVariants: string[];
    setCoversChanged: (categories: string[], variants: string[]) => void;
    speedyLogout: boolean;
    setSpeedyLogout: (value: SetStateAction<boolean>) => void;
    userRights: string[] | null;
    summaryErrors: ErrorType[];
    setSummaryErrors: (value: ErrorType[]) => void;
    mojeIdExistence: CallCheckMojeidDataExistenceResponse | null;
    setMojeIdExistence: (value: CallCheckMojeidDataExistenceResponse | null) => void;
    mojeIdClientData: any;
    setMojeIdClientData: (value: any) => void;
    refetchMojeIdExistence: () => void;
}

export const AppContext = createContext<AppContextProps>({
    popupType: null,
    showPopup: (popupType: PopupType | null, popupCallback?: () => void) => {},
    popupCallback: null,
    currentFormik: null,
    setCurrentFormik: (formik: any) => {},
    predefinedIsPep: undefined,
    setPredefinedIsPep: () => {},
    initData: null,
    setInitData: (data: any | null) => {},
    initDataFetchDate: null,
    setInitDataFetchDate: (data: Dayjs | null) => {},
    userData: null,
    setUserData: (data: UserDataType | null) => {},
    basicSuggestedInsurace: null,
    setBasicSuggestedInsurace: (value: any) => {},
    currentModel: null,
    setCurrentModel: (value: SetStateAction<ModelProps | null>) => {},
    clearCurrentModel: (force?: boolean) => {},
    saveCurrentModel: (data: ModelProps) => new Promise(() => {}),
    coverChangedCategories: [],
    coverChangedVariants: [],
    setCoversChanged: (categories: string[], variants: string[]) => {},
    speedyLogout: false,
    setSpeedyLogout: (value: SetStateAction<boolean>) => {},
    userRights: null,
    summaryErrors: [],
    setSummaryErrors: (value: ErrorType[]) => {},
    mojeIdExistence: null,
    setMojeIdExistence: (value: CallCheckMojeidDataExistenceResponse | null) => {},
    mojeIdClientData: null,
    setMojeIdClientData: (value: any) => {},
    refetchMojeIdExistence: () => {},
});

interface ContextProps {
    children: React.ReactNode;
}

export const Context = ({ children }: ContextProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const path = location.pathname;
    const token = getToken();

    const [popupType, setPopupType] = useState<PopupType | null>(null);
    const [popupCallback, setPopupCallback] = useState<() => void>();
    const [currentFormik, setCurrentFormik] = useState(null);
    const [predefinedIsPep, setPredefinedIsPep] = useState<boolean | undefined>(undefined);
    const [initData, setInitData] = useState<any | null>(null);
    const [initDataFetchDate, setInitDataFetchDate] = useState<Dayjs | null>(null);
    const [userData, setUserData] = useState<UserDataType | null>(null);
    const [userRights, setUserRights] = useState<string[] | null>(null);
    const [basicSuggestedInsurace, setBasicSuggestedInsurace] = useState<ModelProps | null>(null);
    const [currentModel, setCurrentModel] = useState<ModelProps | null>(null);
    const [, setSearchParams] = useSearchParams();
    const [speedyLogout, setSpeedyLogout] = useState(false);
    const [popupNavigateDisable, setPopupNavigateDisable] = useState(false);
    const [modelSaved, setModelSaved] = useState(false);
    const [summaryErrors, setSummaryErrors] = useState<ErrorType[]>([]);
    const [mojeIdExistence, setMojeIdExistence] = useState<CallCheckMojeidDataExistenceResponse | null>(null);
    const [mojeIdClientData, setMojeIdClientData] = useState<any>(null);

    Yup.setLocale({
        mixed: {
            required: t('common.formErrors.requiredField') || '',
        },
        string: {
            email: t('common.formErrors.wrongEmailFormat') || '',
            min: t('common.formErrors.minLenght2Field') || '',
        },
        date: {
            min: ({ min }) => t('common.formErrors.minDateTo', { date: dayjs(min).format(HUMAN_DATE_FORMAT) }) || '',
            max: ({ max }) => t('common.formErrors.maxDateTo', { date: dayjs(max).format(HUMAN_DATE_FORMAT) }) || '',
        },
    });

    const clearCurrentModel = (force?: boolean) => {
        setSearchParams({});
        setCurrentModel(null);
        setBasicSuggestedInsurace(null);
        setMojeIdExistence(null);
        if (modelSaved || force) {
            setPredefinedIsPep(undefined);
            setModelSaved(false);
        }
    };

    const saveCurrentModel = (data: ModelProps) => {
        setModelSaved(true);
        return callSaveModelData({ data: { jsonOnlineModel: JSON.stringify(data) } }, token!);
    };

    useEffect(() => {
        i18n.changeLanguage(userData?.language ? userData.language : 'pl');
    }, [userData]);

    // Reset popup and popup content on route change
    useEffect(() => {
        // Add/remove forced scrollbar used on coverage adjustment, prevents layout shift
        if (path === ROUTE.COVER_ADJUST) {
            document.body.classList.add('force-scrollbar');
        } else {
            document.body.classList.remove('force-scrollbar');
        }

        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        pushStateListener();
        window.addEventListener('popstate', disableNavigation);

        return () => {
            window.removeEventListener('popstate', disableNavigation);
        };
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        const onUnload = (e: any) => {
            e.preventDefault();
            return (e.returnValue = 'Are you sure you want to close?');
        };

        if (currentModel && !isFinishedLocation(location.pathname)) {
            window.addEventListener('beforeunload', onUnload);
        }

        return () => window.removeEventListener('beforeunload', onUnload);
    }, [currentModel, location.pathname]);

    const showPopup = (popupType: PopupType | null, popupCallback?: () => void) => {
        setPopupType(popupType);
        setPopupCallback(() => popupCallback);
    };

    const disableNavigation = () => {
        if (userData) {
            showPopup(null);
            setPopupNavigateDisable(true);
        }
        pushStateListener();
    };

    useEffect(() => {
        if (popupNavigateDisable) {
            showPopup('navigation-buttons-disabled', () => setPopupNavigateDisable(false));
        }
    }, [popupNavigateDisable]);

    useEffect(() => {
        if (token && !isTokenExpired(token)) {
            callGetDashboardRights({ token })
                .then((response) => response.data)
                .then(setUserRights);
        }
    }, [token]);

    const coverChangedCategories = useMemo(
        () => currentModel?.Settings?.IllustrationSettings?.CoverAdjustCategories || [],
        [currentModel]
    );

    const coverChangedVariants = useMemo(
        () => currentModel?.Settings?.IllustrationSettings?.CoverAdjustVariants || [],
        [currentModel]
    );

    const setCoversChanged = (categories: string[], variants: string[]) => {
        if (currentModel) {
            // @ts-ignore
            setCurrentModel((prev) => ({
                ...prev,
                Settings: {
                    ...currentModel.Settings,
                    IllustrationSettings: {
                        ...currentModel.Settings.IllustrationSettings,
                        CoverAdjustCategories: categories,
                        CoverAdjustVariants: variants,
                    },
                },
            }));
        }
    };

    useEffect(() => {
        if (popupType) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [popupType]);

    const refetchMojeIdExistence = () => {
        if (currentModel && token) {
            callCheckMojeidDataExistence({ data: { onlinePolicyExternalId: currentModel.ExternalId }, token })
                .then((response) => response.data)
                .then((result) => setMojeIdExistence(result));
        }
    };

    return (
        <AppContext.Provider
            value={{
                popupType,
                showPopup,
                popupCallback,
                currentFormik,
                setCurrentFormik,
                predefinedIsPep,
                setPredefinedIsPep,
                initData,
                setInitData,
                initDataFetchDate,
                setInitDataFetchDate,
                userData,
                setUserData,
                basicSuggestedInsurace,
                setBasicSuggestedInsurace,
                currentModel,
                setCurrentModel,
                clearCurrentModel,
                saveCurrentModel,
                coverChangedCategories,
                coverChangedVariants,
                setCoversChanged,
                speedyLogout,
                setSpeedyLogout,
                userRights,
                summaryErrors,
                setSummaryErrors,
                mojeIdExistence,
                setMojeIdExistence,
                mojeIdClientData,
                setMojeIdClientData,
                refetchMojeIdExistence,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

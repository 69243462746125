import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import Image from '../assets/images/person-pc.png';
import { Title } from '../components/common/Title';
import { Input } from '../components/common/Input';
import { Card } from '../components/common/Card';
import { Link } from '../components/common/Link';
import { Button } from '../components/common/Button';
import { AppContext } from '../components/Context';
import { Head } from '../components/Head';
import { CustomFormik } from '../components/CustomFormik';
import { FormError } from '../components/common/FormError';
import { callLoginAgent } from '../apis/authentications';
import { parseUserDataFromToken } from '../utils';
import { ROUTE } from '../constants';
import { Layout } from '../components/Layout';

interface LoginFormData {
    loginName: string;
    password: string;
}

export const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formError, setFormError] = useState<string | false>(false);

    const formSchema = Yup.object().shape({
        loginName: Yup.string().required(),
        password: Yup.string().required(),
    });

    const onClickSwitch = () => {
        switch (window.location.hostname) {
            case 'sp-prod-simagepl-ne-app01.azurewebsites.net':
            case 'agent.mojasimplea.pl':
                window.location.href = 'https://mojasimplea.pl/';
                break;
            case 'poland-agents-test.simplea.cz':
                window.location.href = 'https://pp-pl-fix-moje-zivotko.testovat.eu/';
                break;
            case '127.0.0.1':
            case 'localhost':
            case 'sp-test-simagepl-ne-app01.azurewebsites.net':
            default:
                window.location.href = 'https://pp-pl-test-moje-zivotko.testovat.eu/';
                break;
        }
    };

    return (
        <Layout withoutPolicy hideProgressBar hideSaveButton hideUserMenu hideFooter>
            <div className="flex w-full flex-col items-center">
                <Head heading1={t('pages.login.title')} heading2={t('pages.login.subtitle')} />

                <div className="flex w-full flex-col justify-center md:flex-row">
                    <img
                        src={Image}
                        alt=""
                        className="h-auto w-[9.375rem] grow-0 self-center md:w-[16rem] lg:w-[28.125rem]"
                        data-test="image"
                    />

                    <Card className="w-full max-w-[28.125rem] p-4 md:w-full md:p-8 mdmax:mx-auto" data-test="loginCard">
                        <div className="mb-6 flex justify-between">
                            <Title tag="h3" size="lg" fontWeight="medium" className="ml-4" data-test="loginTitle">
                                {t('pages.login.card.title')}
                            </Title>
                            <Button
                                size="md"
                                isOutlined
                                className="bg-transparent"
                                icon="switch"
                                onClick={onClickSwitch}
                                data-test="clientPortalButton"
                            >
                                {t('pages.login.clientsLogin')}
                            </Button>
                        </div>

                        <CustomFormik<LoginFormData>
                            initialValues={{ loginName: '', password: '' }}
                            validationSchema={formSchema}
                            onSubmit={(v) => {
                                setIsSubmitting(true);
                                setFormError(false);
                                callLoginAgent({
                                    loginName: v?.loginName,
                                    password: v?.password,
                                })
                                    .then((res) => {
                                        setIsSubmitting(false);
                                        navigate(ROUTE.DASHBOARD_MAIN);

                                        if (window && res.data.AccessToken) {
                                            localStorage.setItem('token', res.data.AccessToken);
                                            ctx.setUserData(parseUserDataFromToken(res.data.AccessToken));
                                        }
                                    })
                                    .catch((err) => {
                                        if (err.response?.data?.Errors?.[0]?.ErrorCode === 'err_auth_acc_locked') {
                                            setFormError(t<string>('common.formErrors.loginIsLocked'));
                                        } else {
                                            setFormError(t<string>('common.formErrors.invalidPasswordOrLogin'));
                                        }
                                        setIsSubmitting(false);
                                        localStorage.removeItem('token');
                                    });
                            }}
                        >
                            <div className="flex flex-col gap-y-6">
                                <Field
                                    component={Input}
                                    type="text"
                                    label={`${t('common.formFields.loginName')}:`}
                                    name="loginName"
                                    data-test="loginName"
                                />
                                <Field
                                    component={Input}
                                    type="password"
                                    label={`${t('common.formFields.password')}:`}
                                    name="password"
                                    data-test="password"
                                />

                                <FormError error={formError} data-test="errorMessage" />

                                <div className="flex items-center justify-between">
                                    <Link
                                        onClick={() => {
                                            ctx.showPopup('lost-password');
                                        }}
                                        data-test="lostPasswordLink"
                                    >
                                        {`${t('common.forgotPassword')}?`}
                                    </Link>
                                    <Button isDisabled={isSubmitting} isSubmit data-test="loginButton">
                                        {t('common.login')}
                                    </Button>
                                </div>
                            </div>
                        </CustomFormik>
                    </Card>
                </div>
            </div>
        </Layout>
    );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DashboardContext } from './Context';
import { hasPermission, useTableContext } from '../../../../utils/hooks';
import { callMyClientList } from '../../../../apis/dashboard';
import DataTable from '../../../../components/DataTable/DataTable';
import { Column } from '../../../../components/DataTable';
import { Client } from '../types';
import MyClientsToggle from './MyClientsToggle';
import { AGENT_PORTAL_RIGHTS, HUMAN_DATE_FORMAT } from '../../../../constants';
import { AppContext } from '../../../../components/Context';
import FullTextSearch from '../../../../components/FullTextSearch';

const MyClientsList = () => {
    const { t } = useTranslation();
    const { myClients, setMyClients } = useContext(DashboardContext);
    const ctx = useContext(AppContext);
    const { userRights } = ctx;

    const { fullText, handleFullTextSearchChange, ...tableProps } = useTableContext<Client>(
        myClients,
        setMyClients,
        callMyClientList,
        null,
        {
            simpleFilterContent: (data: Client) => ({
                ClientName: [data.ClientLastName, data.ClientMiddleName, data.ClientFirstName]
                    .join(' ')
                    .replace('  ', ' '),
                ClientCompanyName: data.ClientCompanyName,
                ClientBirthDt: dayjs(data.ClientBirthDt).format(HUMAN_DATE_FORMAT),
                IdentificationNo: data.IdentificationNo,
            }),
        }
    );

    return (
        <>
            <FullTextSearch value={fullText} handleSubmit={handleFullTextSearchChange} />
            <DataTable<Client>
                // disablePagination
                // disableRecordsOnPage
                toggableRows={hasPermission(AGENT_PORTAL_RIGHTS.CLIENT_DETAIL, userRights)}
                {...tableProps}
                onToggleContent={(data) => <MyClientsToggle data={data} />}
                data-test="myClientsList"
            >
                <Column
                    // sortable
                    dataIndex="PolicyHolder"
                    label={t('dashboard.common.policyholder')}
                    render={(data: Client) =>
                        [data.ClientLastName, data.ClientMiddleName, data.ClientFirstName].join(' ')
                    }
                />
                <Column
                    // sortable
                    dataIndex="BirthDate"
                    label={t('dashboard.common.birthDate')}
                    render={(data: Client) => dayjs(data.ClientBirthDt).format(HUMAN_DATE_FORMAT)}
                />
                <Column
                    // sortable
                    dataIndex="IdentificationNo"
                    label={t('dashboard.common.identificationNo')}
                />
            </DataTable>
        </>
    );
};

export default MyClientsList;

import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import DashboardMenu from './components/Menu/DashboardMenu';
import MyPolicyList from './components/MyPolicyList';
import Main from './components/Main';
import DraftsList from './components/DraftsList';
import MyClientsList from './components/MyClientsList';
import Contacts from './components/Contacts';
import Context from './components/Context';
import { AppContext } from '../../../components/Context';
import { ProtectedRoute } from '../../../components/common/ProtectedRoute';
import { AGENT_PORTAL_RIGHTS } from '../../../constants';
import AgencyPolicyList from './components/AgencyPolicyList';
import AgencyClientsList from './components/AgencyClientsList';
import { Layout } from '../../../components/Layout';
import Knowledge from './components/Knowledge/Knowledge';

const Dashboard = () => {
    const { clearCurrentModel } = useContext(AppContext);

    useEffect(() => {
        clearCurrentModel(true);
        // eslint-disable-next-line
    }, []);

    return (
        <Layout withoutPolicy hideProgressBar hideSaveButton hideFooter>
            <Context>
                <div className="dashboard flex gap-x-4">
                    <div className="w-[48px] lg:w-64">
                        <DashboardMenu />
                    </div>
                    <div className="w-[calc(100%-48px]" style={{ width: 'calc(100% - 64px)' }}>
                        <Routes>
                            <Route path="main" Component={Main} />
                            <Route
                                path="my-policy"
                                element={
                                    <ProtectedRoute
                                        isAuthorizationProtected
                                        permission={AGENT_PORTAL_RIGHTS.POLICY_LIST}
                                    >
                                        <MyPolicyList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="agency-policy"
                                element={
                                    <ProtectedRoute
                                        isAuthorizationProtected
                                        permission={AGENT_PORTAL_RIGHTS.POLICY_LIST_AGENCY}
                                    >
                                        <AgencyPolicyList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="drafts"
                                element={
                                    <ProtectedRoute
                                        isAuthorizationProtected
                                        permission={AGENT_PORTAL_RIGHTS.DRAFT_LIST}
                                    >
                                        <DraftsList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="my-clients"
                                element={
                                    <ProtectedRoute
                                        isAuthorizationProtected
                                        permission={AGENT_PORTAL_RIGHTS.CLIENT_LIST}
                                    >
                                        <MyClientsList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="agency-clients"
                                element={
                                    <ProtectedRoute
                                        isAuthorizationProtected
                                        permission={AGENT_PORTAL_RIGHTS.CLIENT_LIST_AGENCY}
                                    >
                                        <AgencyClientsList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="knowledge/:section" Component={Knowledge} />
                            <Route path="contacts" Component={Contacts} />
                            <Route path="*" element={<Navigate to="main" replace />} />
                        </Routes>
                    </div>
                </div>
            </Context>
        </Layout>
    );
};

export default Dashboard;

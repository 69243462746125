import React from 'react';
import { useTranslation } from 'react-i18next';
import unextLogo from '../../../../assets/images/unext-logo.png';

const Contacts = () => {
    const { t } = useTranslation();

    return (
        <div className="flex h-full items-center justify-center">
            <div className="mt-40">
                <img src={unextLogo} alt="" className="mb-16" data-test="unextLogo" />
                <div className="mb-10 flex justify-between border-b-1 pb-2 pt-2 text-[16px]">
                    <span data-test="phoneLabel">{t('dashboard.common.phone')}</span>
                    <a href="tel:+48221009977" className="text-right" data-test="phoneLink">
                        <strong className="font-semibold">
                            +48 22 100 99 77
                            <br />
                            <div data-test="workHours">{t('dashboard.common.workHours')}</div>
                        </strong>
                    </a>
                </div>
                <div className="mb-10 flex justify-between border-b-1 pb-2 pt-2 text-[16px]">
                    <span className="font-light" data-test="emailLabel">
                        {t('dashboard.common.emailAddress')}
                    </span>
                    <a href="mailto:agenci@simplea.pl" data-test="emailLink">
                        <strong className="font-semibold">agenci@simplea.pl</strong>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contacts;

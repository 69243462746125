import cn from 'classnames';
import { ReactComponent as ChevronUpIcon } from '../assets/images/chevron-up.svg';
import { ReactComponent as UserIcon } from '../assets/images/user.svg';
import { ReactComponent as LockIcon } from '../assets/images/lock.svg';
import { ReactComponent as InfoIcon } from '../assets/images/info.svg';
import { ReactComponent as LogoutIcon } from '../assets/images/logout.svg';
import { ReactComponent as TrashIcon } from '../assets/images/trash.svg';
import { ReactComponent as SaveIcon } from '../assets/images/save.svg';
import { ReactComponent as CloseIcon } from '../assets/images/close.svg';
import { ReactComponent as LogoIcon } from '../assets/images/simplea.svg';
import { ReactComponent as FileIcon } from '../assets/images/file.svg';
import { ReactComponent as QuestionIcon } from '../assets/images/question.svg';
import { ReactComponent as AvatarIcon } from '../assets/images/avatar.svg';
import { ReactComponent as AvatarLightIcon } from '../assets/images/avatar-light.svg';
import { ReactComponent as DotsIcon } from '../assets/images/dots.svg';
import { ReactComponent as BulbIcon } from '../assets/images/bulb.svg';
import { ReactComponent as ShutterIcon } from '../assets/images/shutter.svg';
import { ReactComponent as WorldIcon } from '../assets/images/world.svg';
import { ReactComponent as CategoryIcon } from '../assets/images/category.svg';
import { ReactComponent as Close2Icon } from '../assets/images/close2.svg';
import { ReactComponent as Close3Icon } from '../assets/images/close3.svg';
import { ReactComponent as SwitchIcon } from '../assets/images/switch.svg';
import { ReactComponent as CheckIcon } from '../assets/images/check.svg';
import { ReactComponent as SpinnerIcon } from '../assets/images/spinner.svg';

interface IconProps {
    name: string;
    onClick?: (e: any) => void;
    fill?: string;
    stroke?: string;
    className?: string;
}

export const Icon = ({ name, ...props }: IconProps) => {
    switch (name) {
        case 'chevron-up':
            return <ChevronUpIcon {...props} />;
        case 'user':
            return <UserIcon {...props} />;
        case 'info':
            return <InfoIcon {...props} />;
        case 'lock':
            return <LockIcon {...props} />;
        case 'logout':
            return <LogoutIcon {...props} />;
        case 'trash':
            return <TrashIcon {...props} />;
        case 'save':
            return <SaveIcon {...props} />;
        case 'close':
            return <CloseIcon {...props} />;
        case 'close-simple':
            return <Close2Icon {...props} />;
        case 'close3':
            return <Close3Icon {...props} />;
        case 'logo':
            return <LogoIcon {...props} />;
        case 'file':
            return <FileIcon {...props} />;
        case 'question':
            return <QuestionIcon {...props} />;
        case 'avatar':
            return <AvatarIcon {...props} />;
        case 'avatar-light':
            return <AvatarLightIcon {...props} />;
        case 'dots':
            return <DotsIcon {...props} />;
        case 'bulb':
            return <BulbIcon {...props} />;
        case 'shutter':
            return <ShutterIcon {...props} />;
        case 'world':
            return <WorldIcon {...props} />;
        case 'category':
            return <CategoryIcon {...props} />;
        case 'switch':
            return <SwitchIcon {...props} />;
        case 'check':
            return <CheckIcon {...props} />;
        case 'spinner':
            return <SpinnerIcon {...props} className={cn(props.className, 'mr-2 h-6 w-6 animate-spin')} />;
        default:
            return <></>;
    }
};

import React from 'react';
import { useParams } from 'react-router-dom';
import SectionLinks from './SectionLinks';
import { SectionCode, sections } from './types';
import ProductSpecification from './ProductSpecification';
import Glossary from './Glossary';
import ForWhoIsProduct from './ForWhoIsProduct';
import FAQ from './FAQ';
import Documents from './Documents';

const Knowledge = () => {
    const { section } = useParams();

    const currentSection: string = section && sections.includes(section) ? section : SectionCode.PRODUCT_SPECIFICATION;

    return (
        <>
            <SectionLinks section={currentSection} />
            {currentSection === SectionCode.PRODUCT_SPECIFICATION ? (
                <ProductSpecification />
            ) : currentSection === SectionCode.FOR_WHO_IS_PRODUCT ? (
                <ForWhoIsProduct />
            ) : currentSection === SectionCode.GLOSSARY ? (
                <Glossary />
            ) : currentSection === SectionCode.FAQ ? (
                <FAQ />
            ) : currentSection === SectionCode.DOCUMENTS ? (
                <Documents />
            ) : null}
        </>
    );
};

export default Knowledge;

import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DraftsToggle from './DraftsToggle';
import { DashboardContext } from './Context';
import { Draft } from '../types';
import DataTable from '../../../../components/DataTable/DataTable';
import { Column } from '../../../../components/DataTable';
import { useTableContext } from '../../../../utils/hooks';
import { callDraftList } from '../../../../apis/dashboard';
import { HUMAN_DATE_FORMAT } from '../../../../constants';
import FullTextSearch from '../../../../components/FullTextSearch';
import { AppContext } from '../../../../components/Context';

const DraftsList = () => {
    const { t } = useTranslation();
    const { initData } = useContext(AppContext);
    const { drafts, setDrafts } = useContext(DashboardContext);

    const prefixes = useMemo(() => initData?.InternationalPhonePrefixes || [], [initData]);

    const getPhonePrefix = useCallback(
        (prefixId: string) => {
            const prefix = prefixes?.find((p: any) => p.ExternalId === prefixId);
            if (prefix) {
                return prefix.PhonePrefix;
            }
            return '';
        },
        [prefixes]
    );

    const { fullText, handleFullTextSearchChange, ...tableProps } = useTableContext(
        drafts,
        setDrafts,
        callDraftList,
        null,
        {
            simpleFilterContent: (data: Draft) => ({
                InsertedDt: dayjs(data.InsertedDt).format(HUMAN_DATE_FORMAT),
                IllustrationCustomName: data.IllustrationCustomName,
                ClientName: `${data.ClientLastName} ${data.ClientFirstName}`,
                ClientEmail: data.ClientEmail,
                ClientPhone:
                    data.ClientPhonePrefixExternalId && data.ClientPhone
                        ? `${getPhonePrefix(data.ClientPhonePrefixExternalId)} ${data.ClientPhone}`
                        : '',
                ClientBirthDt: dayjs(data.ClientBirthDt).format(HUMAN_DATE_FORMAT),
            }),
        }
    );

    return (
        <>
            <FullTextSearch value={fullText} handleSubmit={handleFullTextSearchChange} />
            <DataTable<Draft>
                // disablePagination
                // disableRecordsOnPage
                toggableRows
                {...tableProps}
                onToggleContent={(data) => <DraftsToggle data={data} />}
                data-test="draftList"
            >
                <Column
                    // sortable
                    dataIndex="InsertedDt"
                    label={t('dashboard.common.createdDate')}
                    render={(data: Draft) => dayjs(data.InsertedDt).format(HUMAN_DATE_FORMAT)}
                />
                <Column
                    // sortable
                    dataIndex="BeginingAt"
                    label={t('dashboard.common.beginningDate')}
                    render={(data) => dayjs(data.BeginningDt).format(HUMAN_DATE_FORMAT)}
                />
                <Column
                    // sortable
                    dataIndex="BirthDate"
                    label={t('dashboard.common.birthDate')}
                    render={(data: Draft) => dayjs(data.ClientBirthDt).format(HUMAN_DATE_FORMAT)}
                />
                <Column
                    // sortable
                    dataIndex="IllustrationCustomName"
                    label={t('dashboard.common.calculationName')}
                />
                <Column
                    // sortable
                    dataIndex="PolicyIdentification"
                    label={t('dashboard.common.policyIdentification')}
                    render={(data: Draft) => {
                        const rows = [
                            `${data.ClientLastName || ''} ${data.ClientFirstName || ''}`,
                            data.ClientEmail || '',
                            data.ClientPhonePrefixExternalId && data.ClientPhone
                                ? `${getPhonePrefix(data.ClientPhonePrefixExternalId)} ${data.ClientPhone}`
                                : '',
                        ];
                        return (
                            <>
                                {rows
                                    .filter((row: string) => row.trim() !== '')
                                    .map((row: string, index: number) => (
                                        <p key={index}>{row}</p>
                                    ))}
                            </>
                        );
                    }}
                />
                <Column
                    // sortable
                    dataIndex="ExpireDt"
                    label={t('dashboard.common.expireWithin')}
                    render={(data: Draft) => dayjs(data.ExpireDt).format(HUMAN_DATE_FORMAT)}
                />
            </DataTable>
        </>
    );
};

export default DraftsList;

import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallResetAgentPasswordRequestedProps {
    data: {
        loginEmail: string;
    };
}

export const callResetAgentPasswordRequested = (
    props: CallResetAgentPasswordRequestedProps
): Promise<AxiosResponse<string, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}authentications/reset-agent-password-requested`,
        data: props.data,
    });

import React, { Fragment, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DashboardContext } from './Context';
import { hasPermission, useTableContext } from '../../../../utils/hooks';
import { Policy } from '../types';
import DataTable from '../../../../components/DataTable/DataTable';
import { Column } from '../../../../components/DataTable';
import { callAgencyPolicyList } from '../../../../apis/dashboard';
import AgencyPolicyToggle from './AgencyPolicyToggle';
import { AGENT_PORTAL_RIGHTS, HUMAN_DATE_FORMAT } from '../../../../constants';
import PolicyStatus from './PolicyStatus';
import { AppContext } from '../../../../components/Context';
import PolicyFilter, { PolicyFilterType, initPolicyFilter } from './PolicyFilter';
import { filterPolicy, getPolicyServicingAgent, getPolicyWritingAgent } from '../helpers';
import PolicyPaymentSymbols from './PolicyPaymentSymbols';

const AgencyPolicyList = () => {
    const { t } = useTranslation();
    const { agencyPolicy, setAgencyPolicy } = useContext(DashboardContext);
    const ctx = useContext(AppContext);
    const { userRights } = ctx;
    const [filter, setFilter] = useState<PolicyFilterType>(initPolicyFilter);

    const { fullText, handleFullTextSearchChange, refetch, ...tableProps } = useTableContext<Policy>(
        agencyPolicy,
        setAgencyPolicy,
        callAgencyPolicyList,
        filter,
        {
            completeFilter: (data: Policy) => filterPolicy(data, filter),
        }
    );

    const writingAgents = useMemo(() => {
        return agencyPolicy.records.reduce((acc: string[], policy) => {
            const agentName = getPolicyWritingAgent(policy);
            if (!acc.includes(agentName)) {
                acc.push(agentName);
            }
            return acc;
        }, []);
    }, [agencyPolicy.records]);

    const servisingAgents = useMemo(() => {
        return agencyPolicy.records.reduce((acc: string[], policy) => {
            const agentName = getPolicyServicingAgent(policy);
            if (!acc.includes(agentName)) {
                acc.push(agentName);
            }
            return acc;
        }, []);
    }, [agencyPolicy.records]);

    return (
        <>
            <PolicyFilter
                filter={filter}
                withAgents
                onChange={(f) => setFilter(f)}
                writingAgents={writingAgents}
                servisingAgents={servisingAgents}
            />
            <DataTable<Policy>
                // disablePagination
                // disableRecordsOnPage
                toggableRows={hasPermission(AGENT_PORTAL_RIGHTS.POLICY_DETAIL_AGENCY, userRights)}
                {...tableProps}
                onToggleContent={(data) => <AgencyPolicyToggle data={data} />}
                data-test="agencyPolicyList"
            >
                <Column
                    // sortable
                    dataIndex="PolicyNo"
                    label={t('dashboard.common.policyNumber')}
                />
                <Column
                    // sortable
                    dataIndex="PolicyHolder"
                    label={t('dashboard.common.policyholder')}
                    render={(data: Policy) => `${data.OwnerLastName} ${data.OwnerFirstName}`}
                />
                <Column
                    // sortable
                    dataIndex="IllustrationDt"
                    label={t('dashboard.common.createdDate')}
                    render={(data) => dayjs(data.IllustrationDt).format(HUMAN_DATE_FORMAT)}
                />
                <Column
                    // sortable
                    dataIndex="BeginningDt"
                    label={t('dashboard.common.beginningDate')}
                    render={(data) => dayjs(data.BeginningDt).format(HUMAN_DATE_FORMAT)}
                />
                <Column
                    // sortable
                    dataIndex="PolicyStatusCode"
                    label={t('dashboard.common.currentStatus')}
                    render={(data: Policy) => <PolicyStatus statusCode={data.PolicyStatusCode} />}
                />
                {/* <Column
                    // sortable
                    dataIndex="WritingAgent"
                    label={t('dashboard.common.writingAgent')}
                    render={getPolicyWritingAgent}
                /> */}
                <Column
                    // sortable
                    dataIndex="ServicingAgent"
                    label={t('dashboard.common.servicingAgent')}
                    render={getPolicyServicingAgent}
                />
                {/* <Column
                    // sortable
                    dataIndex="OriginalWritingAgentCompanyName"
                    label={t('dashboard.common.servicingAgency')}
                    render={getPolicyAgencyName}
                /> */}
                <Column
                    // sortable
                    dataIndex="PaidToDt"
                    label={t('dashboard.common.paidToDt')}
                    render={(data: Policy) => (
                        <div className="whitespace-nowrap">
                            {data.PaidToDt ? (
                                <Fragment>
                                    <PolicyPaymentSymbols data={data} />
                                    {dayjs(data.PaidToDt).format(HUMAN_DATE_FORMAT)}
                                </Fragment>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                />
                <Column
                    // sortable
                    dataIndex="TerminationDt"
                    label={t('dashboard.common.terminationDt')}
                    render={({ TerminationDt }: Policy) => (
                        <div className="whitespace-nowrap">
                            {TerminationDt ? dayjs(TerminationDt).format(HUMAN_DATE_FORMAT) : ''}
                        </div>
                    )}
                />
            </DataTable>
        </>
    );
};

export default AgencyPolicyList;

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps, useFormikContext } from 'formik';

import { Label } from './Label';
import { FormError } from './FormError';
import { Button } from './Button';
import { Icon } from '../Icon';

interface FileInputProps {
    label?: string;
    className?: string;
    isCenter?: boolean;
    field?: FieldProps['field'];
    error?: string;
    accept?: string;
    disabled?: boolean;
    onChange?: (e: any) => void;
    inputRef?: any;
}

export const FileInput = ({
    label,
    field,
    isCenter,
    error,
    accept,
    disabled = false,
    className,
    onChange,
    inputRef,
    ...props
}: FileInputProps): ReactElement => {
    const { t } = useTranslation();
    const [name, setName] = useState<string | null>(null);
    const formikCtx = useFormikContext();

    useEffect(() => {
        if (field?.value) {
            const splittedName = field?.value.split('\\');
            const newName = splittedName?.[splittedName.length - 1];
            newName && setName(newName);
        } else {
            setName(null);
        }
    }, [field?.value]);

    return (
        <div className={cn(className, 'flex w-full max-w-[30rem] flex-col')}>
            <div className="relative flex w-full items-center gap-x-2">
                <Label
                    isCenter={isCenter}
                    text={label}
                    name={field?.name || ''}
                    className="w-full"
                    childrenClassName={cn(
                        'flex h-[3.25rem] w-full rounded-full border px-4 [&:focus]:border-darkPurple bg-white',
                        isCenter && 'text-center',
                        !!error ? 'border-red' : 'border-purple'
                    )}
                    data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}Label` : undefined}
                >
                    <input
                        id={field?.name}
                        name={field?.name}
                        type="file"
                        value={field?.value}
                        accept={accept}
                        disabled={disabled}
                        onChange={(e) => {
                            onChange && onChange(e);
                            field?.onChange(e);
                        }}
                        className="input-hidden"
                        ref={inputRef}
                        data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}Input` : undefined}
                    />

                    {name && (
                        <div className="w-full self-center overflow-hidden text-ellipsis whitespace-nowrap pr-[7.5rem] text-left">
                            {name}
                        </div>
                    )}

                    <div className="absolute bottom-[0.5rem] right-[0.375rem] flex h-[2.25rem] flex-row items-center">
                        {field?.value && name && (
                            <Icon
                                name="close-simple"
                                className="react-select__clear-indicator"
                                onClick={(e) => {
                                    e?.preventDefault();
                                    e?.stopPropagation();
                                    setName(null);
                                    field?.name && formikCtx.setFieldValue(field?.name, '');
                                }}
                            />
                        )}
                        <Button
                            size="sm"
                            isDisabled={disabled}
                            className="h-[2.25rem]"
                            data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}Attach` : undefined}
                        >
                            {t('common.attach')}
                        </Button>
                    </div>
                </Label>
            </div>

            <FormError
                name={field?.name}
                className={cn('mt-2', isCenter ? 'text-center' : 'pl-4')}
                data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}Error` : undefined}
            />
        </div>
    );
};
